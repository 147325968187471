import React from 'react'
import {Link} from 'gatsby'

const ServicesOne = () => {
    return (
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                                <Link to="/service-assistive-technology">
                                Assistive Technology (Equipment)
                                </Link>
                            </h3>
                            <p>Assistive Technology is any device or system that makes it easier to undertake tasks you would find challenging.</p>

                            <Link className="view-details-btn" to="/service-assistive-technology">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>

                            <h3>
                                <Link to="/functional-capacity-building">
                                Functional Capacity Building and Rehab
                                </Link>
                            </h3>

                            <p>Functional Capacity Building can assist with ensuring clients get more out of life today.</p>
                            
                            <Link className="view-details-btn" to="/functional-capacity-building">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>
   
                            <h3>
                                <Link to="/home-modifications">
                                Home Modifications
                                </Link>
                            </h3>

                            <p>Sometimes, modifications to your home can be necessary for you to continue to live safely and independently.</p>
                            
                            <Link className="view-details-btn" to="/home-modifications">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-segmentation"></i>
                            </div>

                            <h3>
                                <Link to="/behaviour-support">
                                Behaviour Support
                                </Link>
                            </h3>

                            <p>Positive behaviour support is focused on improving a person's quality of life.</p>
                           
                            <Link className="view-details-btn" to="/behaviour-support">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-analytics"></i>
                            </div>

                            <h3>
                                <Link to="/wheelchair-and-seating">
                                Wheelchair and Seating
                                </Link>
                            </h3>

                            <p>Seating systems or equipment which is designed to meet an individuals’ needs for postural support and alignment increases your quality of life. </p>
                            
                            <Link className="view-details-btn" to="/wheelchair-and-seating">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-settings"></i>
                            </div>

                            <h3>
                                <Link to="/home-safety-assessments">
                                Home safety assessments.
                                </Link>
                            </h3>

                            <p>Government guidelines suggest that an environmental review and home hazard modification will reduce the chance of falls for older members of the community.</p>
                            
                            <Link className="view-details-btn" to="/home-safety-assessments">
                                View Details
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne